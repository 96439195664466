import { createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";

const initstate: any = {
  selectedSection: "MAPVIEW",
  activeStep: 1,
  showBookReserve: "",
  selectedPL: {},
  selectedFleetOrg: {},
  allParkingLotLocations: [],
  counts: {
    parkingLots: "",
    activeReservations: "",
    pendingPaymants: "",
    drivers: "",
  },
  filterSearch: {
    duration: "",
    amenities: "",
    amenitiesList: [],
  },
  reservationCreationMode: "manual",
  noOfSlotstoReserve: 0,
  listOfDetailsForReservation: [],
  bookFleetReservation: {},
};
export const FinparkReserveSlice = createSlice({
  name: "finparkReserve",
  initialState: initstate,
  reducers: {
    setAllParkingLotLocations: (state, action) => {
      state.allParkingLotLocations = action.payload;
    },
    setSelectedSection: (state, action) => {
      state.selectedSection = action.payload;
      state.showBookReserve = "";
      state.listOfDetailsForReservation = [];
      state.noOfSlotstoReserve = 0;
      state.bookFleetReservation = {};
    },
    setShowBookReserve: (state, action) => {
      state.showBookReserve = action.payload;
      state.selectedFleetOrg = "";
      state.reservationCreationMode = "manual";
      state.listOfDetailsForReservation = [];
      state.noOfSlotstoReserve = 0;
      state.bookFleetReservation = {};
    },
    setParkingLotCounts: (state, action) => {
      state.counts.parkingLots = action.payload;
    },
    setActiveReservationCounts: (state, action) => {
      state.counts.activeReservations = action.payload;
    },
    setDriverCounts: (state, action) => {
      state.counts.drivers = action.payload;
    },
    setPendingReservationCounts: (state, action) => {
      state.counts.pendingPaymants = action.payload;
    },
    setSelectedPL: (state, action) => {
      state.selectedPL = action.payload;
      state.listOfDetailsForReservation = [];
      state.noOfSlotstoReserve = 0;
    },
    setFilterAmenities: (state, action) => {
      state.filterSearch.amenities = action.payload;
    },
    setFilterAmenitiesList: (state, action) => {
      state.filterSearch.amenitiesList = action.payload;
    },
    removeFilterAmenities: (state, action) => {
      const arr: any = state.filterSearch.amenities
        ?.split(",")
        ?.filter((item: string) => item !== action.payload);

      state.filterSearch.amenities = arr?.join(",");
    },
    setSelectedFleetOrg: (state, action) => {
      state.selectedFleetOrg = action.payload;
      // state.reservationCreationMode = "manual";
      state.listOfDetailsForReservation = [];
      state.noOfSlotstoReserve = 0;
    },
    setReservationCreationMode: (state, action) => {
      state.reservationCreationMode = action.payload;
      state.listOfDetailsForReservation = [];
      state.noOfSlotstoReserve = 0;
    },
    setBookFleetReservation: (state, action) => {
      state.bookFleetReservation = action.payload;
    },
    setNoOfSlotstoReserve: (state, action) => {
      state.noOfSlotstoReserve = action.payload;
    },
    setActiveStepChange: (state, action) => {
      state.activeStep = action.payload;
    },
    addDriverOrAsset: (state, action) => {
      let obj:any = {...action.payload};
      obj.sno = state.listOfDetailsForReservation?.length +1;
      const list: any = [...state.listOfDetailsForReservation, obj]?.map((ele:any,index:number)=>{
        let item:any = {...ele,sno:(index+1)};
        return item; 
      });
      state.listOfDetailsForReservation = list;
    },
    removeDriverOrAsset: (state, action) => {

      const list: any = state.listOfDetailsForReservation?.filter(
        (ele: any) => ele.sno !== action.payload
      )?.map((ele:any,index:number)=>{
        let item:any = {...ele,sno:(index+1)};
        return item; 
      });
      state.listOfDetailsForReservation = list;
    },
    addAminities: (state, action) => {
      state.filterSearch.amenities +=
        state.filterSearch.amenities === ""
          ? action.payload
          : "," + action.payload;
    },
    addDuration: (state, action) => {
      state.filterSearch.duration = action.payload;
    },
    removeDuration: (state, action) => {
      console.log(action.payload, "DU");
      state.filterSearch.duration = "";
    },
  },
});

export const {
  setAllParkingLotLocations,
  setSelectedSection,
  setParkingLotCounts,
  setActiveReservationCounts,
  setDriverCounts,
  setPendingReservationCounts,
  setSelectedPL,
  setSelectedFleetOrg,
  setReservationCreationMode,
  addDriverOrAsset,
  removeDriverOrAsset,
  setShowBookReserve,
  setBookFleetReservation,
  setNoOfSlotstoReserve,
  removeFilterAmenities,
  setFilterAmenitiesList,
  setActiveStepChange,
  addAminities,
  addDuration,
  removeDuration,
} = FinparkReserveSlice.actions;

export default FinparkReserveSlice.reducer;
