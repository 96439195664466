import React, { useContext, useMemo, useState, createContext } from "react";
const SidebarContext = createContext();

export const SidebarContextPrv = ({ children }) => {
  const [sideBar, setSideBar] = useState(false);
  const [orgType, setOrgType] = useState(["FLEETORG"]);
  const [user, setUser] = useState(1);
  const [imageurl, setImageurl] = useState("");
  const [username, setUsername] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [breadCrumpsData, setbreadCrumpsData] = useState({});
  const [dialogueData, setDialogueData] = useState("");
  const [openDialogue, setOpenDialogue] = useState(false);
  const [USCODE, setUSCODE] = useState("");
  const [CADCODE, setCADCODE] = useState("");
  const [isOrgNetworkConfigEnabled, setisOrgNetworkConfigEnabled] = useState("");
  const openSidebar = () => {
    setSideBar(!sideBar);
  };

  //ORG TYPES > orgType: ["FLEETORG", ""],

  const changeUser = (orgKey) => {
    setUser(orgKey);
  };

  const setCountryCodes = (US, CAD) => {
    setUSCODE(US);
    setCADCODE(CAD);
  };

  const changeOrgType = (orgType) => {
    setOrgType(orgType);
  };

  const changeUserName = (name) => {
    setUsername(name);
  };

  const closeDialogueWindow = () => {
    setOpenDialogue(false);
    setDialogueData("");
  };
  const openDialogueWindow = (data) => {
    setOpenDialogue(true);
    setDialogueData(data);
  };

  const memoizedValue = useMemo(
    () => ({
      sideBar,
      openSidebar,
      user,
      changeUser,
      changeUserName,
      username,
      breadCrumpsData,
      imageurl,
      setImageurl,
      setbreadCrumpsData,
      closeDialogueWindow,
      openDialogueWindow,
      dialogueData,
      openDialogue,
      setUserFirstName,
      setUserLastName,
      userFirstName,
      userLastName,
      changeOrgType,
      orgType,
      USCODE,
      CADCODE,
      setCountryCodes,
      isOrgNetworkConfigEnabled,
      setisOrgNetworkConfigEnabled
    }),
    [
      sideBar,
      openSidebar,
      user,
      changeUser,
      changeUserName,
      username,
      breadCrumpsData,
      imageurl,
      setImageurl,
      setbreadCrumpsData,
      openDialogue,
      setUserFirstName,
      setUserLastName,
      userFirstName,
      userLastName,
      changeOrgType,
      orgType,
      USCODE,
      CADCODE,
      setCountryCodes,
      isOrgNetworkConfigEnabled,
      setisOrgNetworkConfigEnabled,
    ]
  );

  return (
    <SidebarContext.Provider value={memoizedValue}>
      {children}
    </SidebarContext.Provider>
  );
};
// make sure use
export const useSidebarContext = () => {
  return useContext(SidebarContext);
};
