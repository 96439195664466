import { createSlice } from "@reduxjs/toolkit";
import { number } from "yup";

export interface Location {
  id: number;
  name: string;
  lat: number;
  lng: number;
  bookedSlots?: number | null;
  secured?: string | null;
  country?: string | null;
  state?: string | null;
  city?: string | null;
  address?: string | null;
  parkingImage?: string | null;
  person?: string | null;
  mobile?: string | null;
  slots?: number | null;
  email?: string | null;
  rating?: number | null;
  status?: string | null;
  distance?:number|null;
  unit?:number|null;
}
export interface Company {
  companyName: String;
  companyID: String;
  orgId: number;
  orgName: String;
  saasOrgId: number;
}
export interface ParkingLot {
  companyName: String;
  generalParking: String;
  parkingLotId: String;
  parkingLotName: String;
  parkingSlots: String;
  rating: String;
  secured: String;
  status: String;
}
export interface DashboardData {
  locations: Location[];
  companyData: Company[];
  parkingLotData: ParkingLot[];
  selectedCompany: string;
  selectedLot: String;
}
let locdata: Location[] = [
  // {
  //   id: 1,
  //   name:"",
  //   lat: 56.130367,
  //   lng: -106.346771,
  //   country: "Canada",
  //   address: "Canada",
  //   parkingImage: "./assets/images/Canada.jpg",
  //   person: "Aron Samuel",
  //   mobile: "5337772895",
  //   slots: 0,
  //   secured:"",
  //   email: "abc@gmail.com",
  //   rating:null
  // }
];
const initstate: DashboardData = {
  locations: locdata,
  companyData: [],
  parkingLotData: [],
  selectedCompany: "",
  selectedLot: "none",
};
export const DashboardSlice = createSlice({
  name: "MapDashboad",
  initialState: initstate,
  reducers: {
    setLocations: (state, action) => {
      state.locations = [...action.payload];
    },
    setCompanies: (state, action) => {
      state.companyData = [...action.payload];
    },
    setParkingLots: (state, action) => {
      state.parkingLotData = [...action.payload];
    },
    setSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload;
    },
    setSelectedLot: (state, action) => {
      state.selectedLot = action.payload;
    },
  },
});

export const {
  setLocations,
  setCompanies,
  setParkingLots,
  setSelectedCompany,
  setSelectedLot,
} = DashboardSlice.actions;

export default DashboardSlice.reducer;
