/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useOutsideClick from "../clickOutSide";

import { useSidebarContext } from "../../Contexts/SidebarContext";
import Logo from "../../images/logo-dark.png";
import Logosm from "../../images/logo-sm.png";
import { searchTree } from "../../utils/constant/dashboardPages";
//switch user dialogue
import SwitchOrganization from "./switchuserdialog";
import ChangePassword from "./changepassworddialog";
import BreadCrumps from "./BreadCrumps";
import ImageLoader from "../parkinglotComponent/ImageLoader";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { showToast } from "../../utils/tools";
import ProfileDialog from "./profiledialog";
import { getMessageText } from "../../utils/message";
import * as LangConst from "../../utils/constant/LangConst";
import { LocalStorageUtil } from "../../utils/LocalStorageUtil";
import { reDirectURLTo } from "../../utils/redirectionUtil";
import axios from "axios";

export default function Navbar() {
  const { openSidebar, sideBar, user, setCountryCodes } = useSidebarContext();
  const location = useLocation();
  useEffect(() => {
    LocalStorageUtil.setItem("finparkLastViewedPage", location?.pathname);
    loadServerandKeys();
  }, [location?.pathname]);

  const loadServerandKeys = async () => {
    let mili = new Date().getTime();
    const res = await axios.get(`../server.json?rand=${mili}`);
    try {
      setCountryCodes(
        res.data.servers.server.USPassCode,
        res.data.servers.server.CanadaPassCode
      );
    } catch (e) {
      console.log(e);
    }
  };

  if (
    location.pathname !== "/finparkRegistration" &&
    location.pathname !== "/globaldashboard" &&
    location.pathname !== "/SignUpPage"
  )
    return (
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/mapdashboard" className="logo logo-dark">
                <span className="logo-sm">
                  <img
                    src="../../assets/images/logo-sm.png"
                    alt="logo-sm"
                    height="22"
                  />
                </span>
                <span className="logo-lg">
                  <img
                    src="../../assets/images/logo-dark.png"
                    alt="logo-dark"
                    height="35"
                  />
                </span>
              </Link>
            </div>
            <button
              style={{ color: "black" }}
              type="button"
              className="btn btn-sm px-3 font-size-24 header-item"
              id="vertical-menu-btn"
              onClick={(e) => openSidebar()}
            >
              <i className="ri-menu-2-line align-middle"></i>
            </button>
            <div className="ms-0 head-page-title header-item font-size-12 d-none d-sm-none d-md-block d-lg-block d-xl-block ms-xl-4">
              <div className="page-title-right">
                <BreadCrumps />
              </div>
            </div>
          </div>
          <div className="topnav-right">
            <UserSettings user={user} />
          </div>
        </div>
        {/* <SideDrawer /> */}
      </header>
    );
  return <></>;
}

export const OrgImage = React.memo((props:any) => {
  const [imageURL, setImageURL] = useState("");
  const api = useAxiosPrivate();
  const { user, imageurl, changeOrgType ,setisOrgNetworkConfigEnabled} = useSidebarContext();
  const getOrganizationDetails = async () => {
    const saasOrgId: any = LocalStorageUtil.getItem("saasOrgId") || "";
    try {
      const { data } = await api.get(
        `/api/organization/${JSON.parse(saasOrgId)}`
      );
      changeOrgType(data?.usageType);
      console.log(data, "HEADER");
      setisOrgNetworkConfigEnabled(data.isOrgNetworkConfigEnabled);
      LocalStorageUtil.setItem("orgStatus", data.status);
      setImageURL(data.logoPath);
    } catch (error) {
      showToast("ERROR", "Error retrieving data");
    }
  };

  useEffect(() => {
    getOrganizationDetails();
  }, [user, imageurl]);
  
  if(props?.noImage && !imageURL)
    return <> </>;

  return (     
    <ImageLoader
      styleObject={props?.styleObject ? props?.styleObject :{}}
      path={imageURL} 
      className={props?.className ? props?.className : "rounded-3 header-profile-user"}
      notFoundImg="./../assets/images/noProfile.png"
    />
  );
});

const UserSettings = (props: any) => {
  const [open, setOpen] = useState("hide");
  const [openSwitch, setOpenSwitch] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);

  const { username, userFirstName, userLastName } = useSidebarContext();
  const wrapper = useRef(null);
  const navigate = useNavigate();
  const saasOrgId: any = LocalStorageUtil.getItem("loggedInSaasOrgId") || "";
  useOutsideClick(wrapper, () => {
    if (open === "show") setOpen("hide");
  });
  const onSwitchUser = (e: any) => {
    e.preventDefault();
    setOpenSwitch(true);
  };
  const onChangePassword = () => {
    setOpenChangePassword(true);
  };
  const onClickProfile = () => {
    setOpenProfile(true);
  };
  const onHandleClose = (e: any) => {
    setOpenChangePassword(false);
    setOpenSwitch(false);
    setOpenProfile(false);
  };
  return (
    <div className="dropdown d-inline-block user-dropdown" ref={wrapper}>
      <button
        type="button"
        className="btn user-dp header-item d-flex"
        id="page-header-user-dropdown"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={(e: any) => {
          setOpen("show");
        }}
      >
        <OrgImage />
        <div className="d-flex flex-column">
          <div className="d-inline-block">
            <span className="d-none d-xl-inline-block ms-1 font-size-12">
              {username}
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </div>
          <div>
            <div
              className="username d-none d-xl-block ms-1 font-size-9"
              style={{
                lineHeight: "0.6rem",
                textAlign: "left",
                overflow: "hidden",
                height: "20px",
                top: "30px",
              }}
            >
              {`${userFirstName} ${userLastName}`}
            </div>
          </div>
        </div>
      </button>

      <div
        className={`dropdown-menu dropdown-menu-end ${open}`}
        style={{ cursor: "pointer" }}
      >
        {/* <!-- item--> */}
        <a className="dropdown-item" onClick={onClickProfile}>
          <i className="ri-user-line align-middle me-1"></i>
          {getMessageText(LangConst.PROFILE, "Profile  ")}
        </a>
        <div className="dropdown-divider"></div>
        <a className="dropdown-item" onClick={onChangePassword}>
          <i className="ri-lock-line align-middle me-1"></i>
          {getMessageText(LangConst.CHANGE_PASSWORD, "Change Password ")}
        </a>

        {saasOrgId === "1" ? (
          <>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item" onClick={(e) => onSwitchUser(e)}>
              <i className="ri-user-line align-middle me-1"></i>
              {getMessageText(
                LangConst.SWITCH_ORGANIZATION,
                "Switch Organization "
              )}
            </a>{" "}
          </>
        ) : (
          ""
        )}
        <div className="dropdown-divider"></div>
        <a
          className="dropdown-item text-danger"
          onClick={() => {
            LocalStorageUtil.setItem("finparkLastViewedPage", "");
            LocalStorageUtil.setItem("token", "");

            // navigate("/");
            reDirectURLTo(navigate, "/");
          }}
        >
          <i className="ri-shut-down-line align-middle me-1 text-danger"></i>{" "}
          {getMessageText(LangConst.LOGOUT, "Logout ")}
        </a>
      </div>
      <SwitchOrganization show={openSwitch} onCancelDialogue={onHandleClose} />
      <ChangePassword
        show={openChangePassword}
        onCancelDialogue={onHandleClose}
      />
      <ProfileDialog show={openProfile} onCancelDialogue={onHandleClose} />
    </div>
  );
};
