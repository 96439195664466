import { NavigateFunction } from "react-router-dom";
import { setSelectedInternalOrg } from "../reducers/organizationSlice";
import { LocalStorageUtil } from "./LocalStorageUtil";

export const reDirectURLTo = (
  navigate: NavigateFunction,
  routeURL: string,
  dispatch: any = null
) => {
  let url: string = routeURL;

  if (url === "/") {
    const lcp = LocalStorageUtil.getItem("finparkLastViewedPage");
    if (lcp.length > 5) {
      LocalStorageUtil.setItem("finparkLastViewedPage", "");
    }
    window.location.assign(window.location.origin);
    return;
  }
  if (url.indexOf("parkingInfo") !== -1) {
    url = "/parkingLot";
  }
  if (url.indexOf("finparkRegistration") !== -1) {
    LocalStorageUtil.setItem("finparkLastViewedPage", "");
    window.location.assign(window.location.origin);
    return;
  }
  if (url.indexOf("editOrganization") !== -1) {
    url = "/organizationDetails";
    if (dispatch) {
      const selectedInternalOrg =
        LocalStorageUtil.getItem("selectedInternalOrg") ||
        LocalStorageUtil.getItem("saasOrgId");
      dispatch(setSelectedInternalOrg(selectedInternalOrg));
    }
  }
  if (url.indexOf("reservationPage") !== -1) {
    url = `/reservationparkdetails/${LocalStorageUtil.getItem(
      "reservationId"
    )}`;
    LocalStorageUtil.removeItem("fromReservationURL");
    LocalStorageUtil.setItem("finparkLastViewedPage", "");
    console.log(url);
  }
  if (url.indexOf("customLogin") !== -1) {
    url = `/customerLogin/${LocalStorageUtil.getItem("customLogin")}`;
    LocalStorageUtil.removeItem("customLogin");
    LocalStorageUtil.setItem("finparkLastViewedPage", "");
  }

  if (url.indexOf("companyProfile") !== -1) {
    url = "/mapDashboard";
    if (dispatch) {
      const selectedInternalOrg =
        LocalStorageUtil.getItem("selectedInternalOrg") ||
        LocalStorageUtil.getItem("saasOrgId");
      dispatch(setSelectedInternalOrg(selectedInternalOrg));
    }
  }
  if (url.indexOf("globaldashboard") !== -1) {
    url = "/globaldashboard";
    LocalStorageUtil.setItem("finparkLastViewedPage", "");
    console.log(url);
  }

  return navigate(url);
};
