import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

export default function CDSelect(props: any) {
  const [value, setValue] = useState<any | null>(null);
  const [open, toggleOpen] = useState(false);
  const [keyname, setKeyName] = useState<any>("");
  const {
    data,
    label,
    value: defaultValue,
    onChange: onChangeValue,
    onClear,
    disabled,
    onSelect,
  } = props;

  const handleClose = () => {
    setDialogValue({
      [keyname]: "",
      orgId: "",
    });
    toggleOpen(false);
  };

  const getOrgName = (val: any, allData: any) => {
    const keyVal = Object.keys(val)[0];
    setKeyName(
      keyVal === "orgId" || keyVal === "saasOrgId" ? "orgName" : keyVal
    );
    const filterData = allData.filter((items: any) => {
      return items[keyVal] == val[keyVal];
    });
    if (filterData.length !== 0) {
      setValue(filterData[0]);
      if (onSelect) {
        onSelect(filterData[0]);
      }
    } else {
      if (keyVal === "orgName") {
        setValue(val[keyVal] === "All" ? { orgName: "All" } : { orgName: "" });
      } else {
        setValue(val[keyVal] === "All" ? { keyVal: "All" } : { keyVal: "" });
      }
    }
  };
  useEffect(() => {
    getOrgName(defaultValue, data);
  }, [data]);

  const [dialogValue, setDialogValue] = React.useState({
    [keyname]: "",
    orgId: "",
  });

  // const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();
  //   setValue({
  //     [keyname]: dialogValue.orgName,
  //     orgId: parseInt(dialogValue.orgId, 10),
  //   });
  //   handleClose();
  // };
  return (
    <>
      <Autocomplete
        sx={{
          "& .MuiOutlinedInput-root": {
            padding: "0px!important",
            paddingLeft: "10px!important",
            height:"50px"
          },
        }}
        fullWidth
        freeSolo
        value={value}
        disabled={disabled}
        onClose={(event:any, newValue)=>{
          if(newValue !== "selectOption"){
            if(event?.target?.value === ""){
              if(onClear) onClear();            
            }
          }
          console.log(event?.target?.value,newValue);          
        }}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                [keyname]: newValue,
                orgId: "",
              });
            });
          } else {
            setValue(newValue);
            // if (newValue !== null) {
              onChangeValue(newValue);
            // }
          }
        }}
        // filterOptions={(options, params) => {
        //   const filtered = filter(options, params);

        //   if (params.inputValue !== "") {
        //     filtered.push({
        //       inputValue: params.inputValue,
        //       orgName: `Add "${params.inputValue}"`,
        //     });
        //   }

        //   return filtered;
        // }}
        options={data}
        getOptionLabel={(option: any) => {
          // e.g. value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          return option[keyname] || "";
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option: any) => (
          <li {...props}>{option[keyname] || ""}</li>
        )}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
      {/* <Dialog open={open} onClose={handleClose}>
        <form>
          <DialogTitle>Add a new film</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Did you miss any film in our list? Please, add it!
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={dialogValue.orgName}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  orgName: event.target.value,
                })
              }
              label="title"
              type="text"
              variant="standard"
            />
            <TextField
              fullWidth
              margin="dense"
              id="name"
              value={dialogValue.orgId}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  orgId: event.target.value,
                })
              }
              label="year"
              type="number"
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </Dialog> */}
    </>
  );
}

export interface DataOptionType {
  type: string;
}
