import * as React from "react";
import Dialog from "@mui/material/Dialog";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogTitle,
} from "@mui/material";
import PaperComponent from "../DraggableComponent/PaperComponent";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { Button } from "@mui/material";
import { useSidebarContext } from "../../Contexts/SidebarContext";

function SuccessDialog() {
  const { openDialogue, dialogueData, closeDialogueWindow } =
    useSidebarContext();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    closeDialogueWindow();
  };

  return (
    <>
      <div>
        <Dialog
          open={openDialogue}
          // onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          // onClose={handleClose}
          PaperProps={{
            sx: {
              margin: 0,
              maxWidth: "800px",
              maxHeight: "300px",
              borderRadius: "0.8rem",
            },
          }}
        >
          <DialogTitle
            className="modal-title"
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
          ></DialogTitle>
          <div className="modal-content">
            <div style={{ textAlign: "center" }}>
              <CheckCircleOutlinedIcon
                style={{ fontSize: "60px", color: "rgb(26 204 4)" }}
              />
            </div>
            {/* <div className="modal-body text-center font-size-14">
              Congratulations you have successfully booked a Reservation.
              <br />
              Your Reservation Code: <b>{dialogueData.reservationCode}</b>
            </div> */}
            {dialogueData}
            <div className="modal-footer border-0">
              <button
                type="button"
                className="btn min btn-outline-dark mx-lg-3 mx-md-3 font-size-12 text-uppercase"
                data-bs-dismiss="modal"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
}
export default SuccessDialog;
