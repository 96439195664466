export const EMAIL: string = "EMAIL";
export const PASS_WORD: string = "PASS_WORD";
export const FORGET_PASSWORD: string = "F_PASS";
export const USER_NAME: string = "USER_NAME";
export const ADMINISTRATOR: string = "ADMINISTRATOR";
export const ORGANIZATIONS: string = "ORGANIZATIONS";
export const ACTIVE_RESERVATIONS: string = "ACTIVE_RESERVATIONS";
export const UPCOMING_RESERVATIONS: string = "UPCOMING_RESERVATIONS";
export const RESERVATION_IN_PROGRESS: string = "RESERVATION_IN_PROGRESS";
export const CANCELLATIONS_IN_THE_LAST_6MONTHS: string =
  "CANCELLATIONS_IN_THE_LAST_6MONTHS";
export const FAILED_PAYMENT: string = "FAILED_PAYMENT";
export const INCOMPLETE_CUSTOMER_PROFILE: string =
  "INCOMPLETE_CUSTOMER_PROFILE";
export const TOTAL_SEARCHES_IN_THE_LAST_6MONTHS: string =
  "TOTAL_SEARCHES_IN_THE_LAST_6MONTHS";
export const RESERVATIONS: string = "RESERVATIONS";
export const DRIVER_NAME: string = "DRIVER_NAME";
export const COUNT: string = "COUNT";
export const EMAIL_ID: string = "EMAIL_ID";
export const PHONE: string = "PHONE";
export const REGISTRATION_DATE: string = "REGISTRATION_DATE";
export const LAST_RESERVATION_DATE: string = "LAST_RESERVATION_DATE";
export const STATUS: string = "STATUS";
export const RESERVATION_ID: string = "RESERVATION_ID";
export const RESERVATION_CODE: string = "RESERVATION_CODE";
export const COMPANY_NAME: string = "COMPANY_NAME";
export const PARKING_LOT: string = "PARKING_LOT";
export const PARKING_STATUS: string = "PARKING_STATUS";
export const RESERVED_BY: string = "RESERVED_BY";
export const DURATION: string = "DURATION";
export const DURATION_PRICING: string = "DURATION_PRICING";
export const PRICING: string = "PRICING";
export const CREATED_BY: string = "CREATED_BY";
export const CREATED_ON: string = "CREATED_ON";
export const LAST_UPDATED_ON: string = "LAST_UPDATED_ON";
export const LAST_UPDATED_BY: string = "LAST_UPDATED_BY";
export const FROM_LOCATION: string = "FROM_LOCATION";
export const TO_LOCATION: string = "TO_LOCATION";
export const SEARCHED_ON: string = "SEARCHED_ON";
export const DASHBOARD: string = "DASHBOARD";
export const MAP_DASHBOARD: string = "MAP_DASHBOARD";
export const FINPARK_ADMIN_ALL_RIGHTS_RESERVED: string =
  "FINPARK_ADMIN_ALL_RIGHTS_RESERVED-";
export const INVOICE_DASHBOARD: string = "INVOICE_DASHBOARD";
export const ADD_ORGANIZATION: string = "ADD_ORGANIZATION";
export const ADDRESS: string = "ADDRESS";
export const COUNTRY: string = "COUNTRY";
export const DOT: string = "DOT";
export const CREATED_DATE: string = "CREATED_DATE";
export const SUB_COUNT: string = "SUB_COUNT";
export const UPDATED_BY: string = "UPDATED_BY";
export const UPDATED_ON: string = "UPDATED_ON";
export const ORGANIZATION_NAME: string = "ORGANIZATION_NAME";
export const ADDRESS_LANE1: string = "ADDRESS_LANE1";
export const CITY: string = "CITY";
export const STATE: string = "STATE";
export const ZIP_CODE: string = "ZIP_CODE";
export const FIRST_NAME: string = "FIRST_NAME";
export const LAST_NAME: string = "LAST_NAME";
export const ORGANIZATION_DETAILS: string = "ORGANIZATION_DETAILS";
export const SUBSCRIPTION_DETAILS: string = "SUBSCRIPTION_DETAILS";
export const BANK_DETAILS: string = "BANK_DETAILS";
export const PAYMENT_DETAILS: string = "PAYMENT_DETAILS";
export const USERS: string = "USERS";
export const GENERAL_CONFIGURATION: string = "GENERAL_CONFIGURATION";
export const ROLE: string = "ROLE";
export const AMENITIES: string = "AMENITIES";
export const IMAGE: string = "IMAGE";
export const AMENITY: string = "AMENITY";
export const EDITED_BY: string = "EDITED_BY";
export const EDITED_ON: string = "EDITED_ON";
export const PARKING_LOT_NAME: string = "PARKING_LOT_NAME";
export const PARKING_LOT_ID: string = "PARKING_LOT_ID";
export const PARKING_LOTS: string = "PARKING_LOTS";
export const RATING: string = "RATING";
export const PARKING_LOT_DETAILS: string = "PARKING_LOT_DETAILS";
export const PARKING_LOT_INFO: string = "PARKING_LOT_INFO";
export const PARKING_SPACE_TYPE: string = "PARKING_SPACE_TYPE";
export const GENERAL_DETAILS: string = "GENERAL_DETAILS";
export const CONTACT: string = "CONTACT";
export const SELECT_COMPANY_NAME: string = "SELECT_COMPANY_NAME";
export const SPACE_TYPE: string = "SPACE_TYPE";
export const SLOT_COUNT: string = "SLOT_COUNT";
export const ADD_PARKING_LOT: string = "ADD_PARKING_LOT";
export const ADD_PRICING: string = "ADD_PRICING";
export const ADD_INVITE: string = "ADD_INVITE";
export const TERM: string = "TERM";
export const HOURLY_RATE: string = "HOURLY_RATE";
export const Add_AMENITIES: string = "Add_AMENITIES";
export const CHECKED: string = "CHECKED";
export const NEARBY_AMENITIES: string = "NEARBY_AMENITIES";
export const ADD_NEARBY_AMENITY: string = "ADD_NEARBY_AMENITY";
export const ADD_CONTACT: string = "ADD_CONTACT";
export const DRIVERS: string = "DRIVERS";
export const ADD_USERS: string = "ADD_USERS";
export const ADD: string = "ADD";
export const REPORTS: string = "REPORTS";
export const CANCEL: string = "CANCEL";
export const SAVE: string = "SAVE";
export const PAYNSUBSCRIBE: string = "Subscribe";
export const ADD_CC: string = "Add Credit card";
export const PAY: string = "Pay";
export const CLOSE: string = "CLOSE";
export const PROFILE: string = "PROFILE";
export const LOGOUT: string = "LOGOUT";
export const SWITCH_USER: string = "SWITCH_USER";
export const MAP: string = "MAP";
export const FINANCIAL_INSTITUTION_NAME: string = "FINANCIAL_INSTITUTION_NAME";
export const ADDRESS_LANE2: string = "ADDRESS_LANE2";
export const CURRENCY: string = "CURRENCY";
export const ACCOUNT: string = "ACCOUNT";
export const RECIPIENT_NAME: string = "RECIPIENT_NAME";
export const ABA_ROUTING_NUMBER: string = "ABA_ROUTING_NUMBER";
export const TYPE: string = "TYPE";
export const SEND_INVITATION: string = "SEND_INVITATION";
export const SELECT_A_STATUS: string = "SELECT_A_STATUS";
export const COMPANY_DASHBOARD: string = "COMPANY_DASHBOARD";
export const LOGIN: string = "LOGIN";
export const CHANGE_PASSWORD: string = "CHANGE_PASSWORD";
export const OLD_PASSWORD: string = "OLD_PASSWORD";
export const NEW_PASSWORD: string = "NEW_PASSWORD";
export const CONFIRM_NEW_PASSWORD: string = "CONFIRM_NEW_PASSWORD";
export const SWITCH_ORGANIZATION: string = "SWITCH_ORGANIZATION";
export const SELECT_AN_ORGANIZATION: string = "SELECT_AN_ORGANIZATION";
export const SWITCH: string = "SWITCH";
export const SALES_AGENT_COMMISION_PERCENTAGE: string =
  "SALES_AGENT_COMMISION_PERCENTAGE";
export const SALES_AGENT_NAME: string = "SALES_AGENT_NAME";
export const SALES_AGENT_MOBILE_NUMBER: string = "SALES_AGENT_MOBILE_NUMBER";
export const SALES_AGENT_EMAIL: string = "SALES_AGENT_EMAIL";
export const I_AUTHORIZE_VHUB_TO_SEND_ME_PROMOTINAL_EMAIL: string =
  "I_AUTHORIZE_VHUB_TO_SEND_ME_PROMOTINAL_EMAIL";
export const FINPARKS_PLATFORM_FEE: string = "FINPARKS_PLATFORM_FEE";
export const CONSOLIDATE_RESERVATION_CHARGES_WILL_BE_PAID_ON: string =
  "CONSOLIDATE_RESERVATION_CHARGES_WILL_BE_PAID_ON";
export const COMPANY: string = "COMPANY";
export const GENERIC_PARKING_SLOTS: string = "GENERIC_PARKING_SLOTS";
export const SECURED: string = "SECURED";
export const DESCRIPTION: string = "DESCRIPTION";
export const PHOTO: string = "PHOTO";
export const RATINGS: string = "RATINGS";
export const SET_PASSWORD: string = "SET_PASSWORD";
export const CONFIRM_PASSWORD: string = "CONFIRM_PASSWORD";
export const PROMATIONAL_MAILS: string = "PROMATIONAL_MAILS";
export const I_ACCEPT_THE_TERMS_CONDITIONS: string =
  "I_ACCEPT_THE_TERMS_CONDITIONS";
export const SUBMIT: string = "SUBMIT";
export const MY_PROFILE: string = "MY_PROFILE";
export const FORGOT_USERNAME_PASSWORD: string = "FORGOT_USERNAME_PASSWORD";
export const COLUMN_SORT: string = "COLUMN_SORT";
export const COLUMN_HEADING: string = "COLUMN_HEADING";
export const DESC: string = "DESC";
export const OPERATION: string = "OPERATION";
export const PARKING_CODE: string = "PARKING_CODE";
export const TIME_ZONE: string = "TIME_ZONE";
export const MAXIMUN_PARKING_SLOTS: string = "MAXIMUN_PARKING_SLOTS";
export const NEARBY_AMENTIES: string = "NEARBY_AMENTIES";
export const ENTRY_INSTRUCTIONS: string = "ENTRY_INSTRUCTIONS";
export const EXIT_INSTRUCTIONS: string = "EXIT_INSTRUCTIONS";
export const CONDITION_RESTRICTION: string = "CONDITION_RESTRICTION";
export const DRIVER: string = "DRIVER";
export const FROM_DATE: string = "FROM_DATE";
export const TO_DATE: string = "TO_DATE";
export const START_DATE: string = "START_DATE";
export const END_DATE: string = "END_DATE";
export const COMPLETED: string = "COMPLETED";
export const ACTIVE: string = "ACTIVE";
export const IN_ACTIVE: string = "IN_ACTIVE";
export const CUSTOMER_NAME: string = "CUSTOMER_NAME";
export const PARKING_LOT_OWNER_DETAILS: string = "PARKING_LOT_OWNER_DETAILS";
export const CONTACT_PERSON: string = "CONTACT_PERSON";
export const CONTACT_NUMBER: string = "CONTACT_NUMBER";
export const DRIVER_DETAILS: string = "DRIVER_DETAILS";
export const RESERVATION_DETAILS: string = "RESERVATION_DETAILS";
export const ACTIVATION_DETAILS: string = "ACTIVATION_DETAILS";
export const PARKING_LOT_ADDRESS: string = "PARKING_LOT_ADDRESS";
export const LINK_ITEM: string = "LINK_ITEM";
export const DEFAULT_PRICE: string = "DEFAULT_PRICE";
export const SERVICE_FEE: string = "SERVICE_FEE";
export const TIME: string = "TIME";
export const AMOUNT: string = "AMOUNT";
export const OF_EVERY_MONTH: string = "OF_EVERY_MONTH";
export const INVITED: string = "INVITED";
export const RESEND: string = "RESEND";
export const TOTAL_PARKING_LOTS_SPACES: string = "TOTAL_PARKING_LOTS_SPACES";
export const INCOMPLETE_PARKING_LOT_PROFILE: string =
  "INCOMPLETE_PARKING_LOT_PROFILE";
export const PARKING_TYPE: string = "PARKING_TYPE";
export const COLUMN_HIDE_SHOW: string = "COLUMN_HIDE_SHOW";
export const VISIBLE: string = "VISIBLE";
export const PARKING_SLOTS: string = "PARKING_SLOTS";
export const IS_SECURED: string = "IS_SECURED";
export const SELECT: string = "SELECT";
export const IS_DEFAULT: string = "IS_DEFAULT";
export const SEARCH: string = "SEARCH";
export const RESERVATION: string = "RESERVATION";
export const ACTIVATION_DATE: string = "ACTIVATION_DATE";
export const TOTAL_RATE: string = "TOTAL_RATE";
export const RESERVATION_CREATION: string = "RESERVATION_CREATION";
export const RESERVATION_START_DATE: string = "RESERVATION_START_DATE";
export const RESERVATION_END_DATE: string = "RESERVATION_END_DATE";
export const BILL_DETAILS: string = "BILL_DETAILS";
export const INVOICES_STATEMENT_OF_INCOME: string =
  "INVOICES_STATEMENT_OF_INCOME";
export const INVOICESCC: string = "INVOICES";
export const INVOICEID: string = "Invoice ID";
export const DATE: string = "DATE";
export const STARTDATE: string = "Start Date";
export const ENDDATE: string = "End Date";
export const DOCUMENT_NUMBER: string = "DOCUMENT_NUMBER";
export const DOCUMENT_TYPE: string = "DOCUMENT_TYPE";
export const COMPANY_PROFILE: string = "COMPANY_PROFILE";
export const COMPANY_DETAILS: string = "COMPANY_DETAILS";
export const PARKING_LOT_PRICING: string = "PARKING_LOT_PRICING";
export const ADD_AMENITY: string = "ADD_AMENITY";
export const BACK_ADMIN: string = "BACK_ADMIN";
export const ENTER_YOUR_EMAIL: string = "ENTER_YOUR_EMAIL";
export const YOUR_ACCOUNT: string = "YOUR_ACCOUNT";
export const SEND: string = "SEND";
export const ASSIGN_PARKING_LOTS: string = "ASSIGN_PARKING_LOTS";
export const SELECT_ALL: string = "SELECT_ALL";
export const ASSIGN: string = "ASSIGN";
export const EDIT_PRICING: string = "EDIT_PRICING";
export const UPDATE: string = "UPDATE";
export const WIFI: string = "WIFI";
export const SHOWER: string = "SHOWER";
export const RESTARENT: string = "RESTARENT";
export const PARKING: string = "PARKING";
export const GAS_STATION: string = "GAS_STATION";
export const TRAILER: string = "TRAILER";
export const TRUCK: string = "TRUCK";
export const REPAIR: string = "REPAIR";
export const DRUG_STORE: string = "DRUG_STORE";
export const EDIT_AMENITY: string = "EDIT_AMENITY";
export const EDIT_USERS: string = "EDIT_USERS";
export const INVOICE_COUNT: string = "INVOICE_COUNT";
export const INVOICE: string = "INVOICE";
export const INVOICE_DATE: string = "INVOICE_DATE";
export const ACTION: string = "ACTION";
export const SERVICE_ITEM: string = "SERVICE_ITEM";
export const TAX: string = "TAX";
export const RENT_PER_HR: string = "RENT_PER_HR";
export const PALTFORM_PERCENTAGE: string = "PALTFORM_PERCENTAGE";
export const PLATFORM_FEE: string = "PLATFORM_FEE";
export const CR_DR: string = "CR_DR";
export const TOTAL_AMOUNT: string = "TOTAL_AMOUNT";
export const TOTAL: string = "TOTAL";
export const PLEASE_ENTERED_THE_REGISTRATION_EMAIL_ID: string =
  "PLEASE_ENTERED_THE_REGISTRATION_EMAIL_ID";
export const TRANSACTION_ID: string = "TRANSACTION_ID";
export const CREDIT_CARD_NUMBER: string = "CREDIT_CARD_NUMBER";
export const ACCOUNT_RECEIVABLE_ID: string = "ACCOUNT_RECEIVABLE_ID";
export const DETAILS: string = "DETAILS";
export const RENT_RER_HOUR: string = "RENT_RER_HOUR";
export const TOTAL_TAX: string = "TOTAL_TAX";
export const BILLING_AMOUNT: string = "BILLING_AMOUNT";
export const PAYMENT_SUCCESS: string = "PAYMENT_SUCCESS";
export const JURISDICATION_TYPE: string = "JURISDICATION_TYPE";
export const STATE_ASSIGNED_NO: string = "STATE_ASSIGNED_NO";
export const JURIS_NAME: string = "JURIS_NAME";
export const RATE: string = "RATE";
export const PAYMENT_STATUS: string = "PAYMENT_STATUS";
export const SOI_DASHBOARD: string = "SOI_DASHBOARD";
export const SOI_COUNT: string = "SOI_COUNT";
export const SOI_ID: string = "SOI_ID";
export const SOI_START_DATE: string = "SOI_START_DATE";
export const SOI_END_DATE: string = "SOI_END_DATE";
export const SWIFT_CODE: string = "SWIFT_CODE";
export const FINANCIAL_INSTITUTIONNUMBER: string =
  "FINANCIAL_INSTITUTIONNUMBER";
export const TRANSIT_NUMBER: string = "TRANSIT_NUMBER";
export const INVOICE_ID: string = "INVOICE_ID";
export const INVOICE_START_DATE: string = "INVOICE_START_DATE";
export const INVOICE_END_DATE: string = "INVOICE_END_DATE";
export const GATE_ACCESS_CODE: string = "GATE_ACCESS_CODE";
export const PREFERRED_LANG: string = "PREFERRED_LANG";
export const GENERAL_SPACES: string = "GENERAL_SPACES";
export const AVAILABLE: string = "AVAILABLE";
export const BOOKED: string = "BOOKED";
export const TOTAL_PARKING_LOTS: string = "TOTAL_PARKING_LOTS";
export const PARKING_ACCESS_CODE: string = "PARKING_ACCESS_CODE";
export const ADDED_DATE: string = "ADDED_DATE";
export const EXPIRE_DATE: string = "EXPIRE_DATE";
export const DOC_EXPIRE_DATE: string = "DOC_EXPIRE_DATE";
export const QR_CODE: string = "QR_CODE";
export const ACCESS_CODE: string = "ACCESS_CODE";
export const TRUCK_DETAILS: string = "TRUCK_DETAILS";
export const TRUCK_NAME: string = "TRUCK_NAME";
export const MODEL: string = "MODEL";
export const COLOUR: string = "COLOUR";
export const LICENSE_PLATE: string = "LICENSE_PLATE";
export const YEAR: string = "YEAR";
export const CONFIRM: string = "CONFIRM";
export const CONFIRM_MSG: string = "CONFIRM_MSG";
export const CONFIRM_MSG_DEL: string = "CONFIRM_MSG_DEL";
export const TRUCK_DETAILS_REQUIRED: string = "TRUCK_DETAILS_REQUIRED";
export const ORGANIZATION: string = "ORGANIZATION";
export const CREATION_DATE: string = "CREATION_DATE";
export const DISCOUNT_CODE: string = "DISCOUNT_CODE";
export const SUBSCRIPTION_AMOUNT: string = "SUBSCRIPTION_AMOUNT";
export const ADD_DISCOUNT_CODE: string = "ADD_DISCOUNT_CODE";
export const ADD_DISCOUNT: string = "ADD_DISCOUNT";
export const DISCOUNT_CODE_ID: string = "DISCOUNT_CODE_ID";
export const VALIDITY: string = "VALIDITY";
export const LIMIT: string = "LIMIT";
export const DISCOUNT_PERCENTAGE: string = "DISCOUNT_PERCENTAGE";
export const FIXED_DISCOUNT: string = "FIXED_DISCOUNT";
export const CREATED: string = "CREATED";
export const LAST_UPDATED: string = "LAST_UPDATED";
export const TRANSACTION_COUNT: string = "TRANSACTION_COUNT";
export const BALANCE_REMAINING: string = "BALANCE_REMAINING";
export const UPDATION_DATE: string = "UPDATION_DATE";
export const DELETE: string = "DELETE";
export const EDIT: string = "EDIT";
export const DISCOUNT_TYPE: string = "DISCOUNT_TYPE";
export const DISCOUNT_CODE_NAME: string = "DISCOUNT_CODE_NAME";
export const CUSTOM_DISCOUNT_CODE: string = "CUSTOM_DISCOUNT_CODE";
export const PERCENTAGE: string = "PERCENTAGE";
export const MAX_LIMIT_PER_CODE: string = "MAX_LIMIT_PER_CODE";
export const TOTAL_VALUE: string = "TOTAL_VALUE";
export const VALID_COUNTRY: string = "VALID_COUNTRY";
export const PROVINCE: string = "PROVINCE";
export const NOTES: string = "NOTES";
export const CODE: string = "CODE";
export const AMOUNT_PERCENTAGE: string = "AMOUNT_PERCENTAGE";
export const USAGE: string = "USAGE";
export const MAX_CAP: string = "MAX_CAP";
export const MAXIMUM_USAGE: string = "MAXIMUM_USAGE";
export const DELETE_DISCOUNT_MSG: string = "DELETE_DISCOUNT_MSG";
export const INSTRUCTIONS: string = "INSTRUCTIONS";
export const MOBILE_NUMBER: string = "MOBILE_NUMBER";
export const REDEEMED: string = "REDEEMED";
export const DISCOUNT_INVOICE: string = "DISCOUNT_INVOICE";
export const REMAINING_USAGE: string = "REMAINING_USAGE";
export const ROWSPERPAGE: number = 10;
export const DATASIZE: number = 10;
export const POBOX_NO: string = "POBOX_NO"; // kalyani
export const CONFIRMATION: string = "CONFIRMATION";
export const ALL: string = "ALL";
export const NEW_DRIVER: string = "NEW_DRIVER";
export const OWNER_COMPANY: string = "OWNER_COMPANY";
export const PAID_BY: string = "PAID_BY";
export const PHONE_1: string = "PHONE_1";
export const SEND_LINK: string = "SEND_LINK";
export const ADD_DRIVER: string = "ADD_DRIVER";
export const EDIT_DRIVER: string = "EDIT_DRIVER";
export const EDIT_DISCOUNT_CODE: string = " EDIT_DISCOUNT_CODE";
export const DOWNLOAD_ALL: string = "DOWNLOAD_ALL";
export const EMPLOYEE_ID: string = "EMPLOYEE_ID";
export const CREATION: string = "CREATION";
export const UPDATED: string = "UPDATED";
export const EMAIL_ID1: string = "EMAIL_ID1";
export const DRIVER_TYPE: string = "DRIVER_TYPE";
export const DRIVER_PHONE_NO: string = "DRIVER_PHONE_NO";
export const ASSET_ID: string = "ASSET_ID";
export const EQUIPMENT_TYPE: string = "EQUIPMENT_TYPE";
export const CONFIRM_MSG_BANK_VERIFY: string = "CONFIRM_MSG_BANK_VERIFY";
export const VERIFY: string = "VERIFY";
export const INVOICES: string = "INVOICES";
export const ADD_PAYMENT: string = "ADD_PAYMENT";
export const CONFIRM_MSG_DEL_CC: string = "CONFIRM_MSG_DEL_CC";
export const FINPARK_RESERVE: string = "FINPARK_RESERVE";
export const PUSH_NOTIFICATION: string = "PUSH_NOTIFICATION";
export const PAYMENT_BY: string = "PAYMENT_BY";
export const ASSETS: string = "ASSETS";
export const ASSET_NAME: string = "ASSET_NAME";
export const ASSET_TYPE: string = "ASSET_TYPE";
export const GROUP_ID: string = "GROUP_ID";
export const PHONE1: string = "PHONE1";
export const BUSINESSWEBSITE = "BUSINESSWEBSITE";
export const BUSINESSNAME = "BUSINESSNAME";
export const ADD_BANK = "ADD_BANK";
export const COMPANYREGNUMBER = "COMPANYREGNUMBER";
export const SERIAL_NO: string = "SERIAL_NO";
export const PRICE: string = "PRICE";
export const RECURRING: string = "RECURRING";
export const ORGANIZATION_TYPE: string = "ORGANIZATION_TYPE";
export const REVENUE: string = "REVENUE";
export const RESERVATION_STATUS: string = "RESERVATION_STATUS";
export const INVOICE_AMOUNT: string = "INVOICE_AMOUNT";
export const SHARELINK: string = "SHARELINK";
export const SEND_PAYMENT_LINK: string = "SEND_PAYMENT_LINK";
export const GROUPID = "GROUPID";
export const ASSETS_NUMBER: string = "ASSETS_NUMBER";
export const BANK_SELCTION_WARNING: string =
  "The bank payment transaction will take 3 to 4 days to complete.";
export const ORGANIZATION_COUNT: string = "ORGANIZATION_COUNT";
export const TRANSACTION_CODE: string = "TRANSACTION_CODE";
export const AMOUNT_PAID: string = "AMOUNT_PAID";
export const SEQUANCE: string = "SEQUANCE";
export const CARD_ID: string = "CARD_ID";
export const PAYMENT_MODE: string = "PAYMENT_MODE";
export const SUBSCRIPTION_TRANS_ID: string = "SUBSCRIPTION_TRANS_ID";
export const RELATION_ID: string = "RELATION_ID";
export const BANK_ID: string = "BANK_ID";
export const START_DATE_TIME: string = "START_DATE_TIME";
export const END_DATE_TIME: string = "END_DATE_TIME";
export const LAST_UPDATED_DATE: string = "LAST_UPDATED_DATE";
export const ORG_ID: string = "ORG_ID";
export const SUBSCRIPTIONS: string = "SUBSCRIPTIONS";
export const SUBSCRIPTION_STATUS: string = "SUBSCRIPTION_STATUS";
export const EXPIRATION_DATE: string = "EXPIRATION_DATE";
export const API_HUB: string = "API_HUB";
export const SUBSCRIPTION_TYPE: string = "SUBSCRIPTION_TYPE";
export const SUBSCRIPTION_NAME: string = "SUBSCRIPTION_NAME";
export const CONFIRM_MSG_DRIVER: string = "CONFIRM_MSG_DRIVER";
export const CONFIRM_MSG_DISSOCIATE_DRIVER: string =
  "CONFIRM_MSG_DISSOCIATE_DRIVER";
export const VALIDATE: string = "VALIDATE";
export const BACK: string = "BACK";
export const CONTINUE: string = "CONTINUE";
export const REVENUE_FORECAST: string = "REVENUE_FORECAST";
export const UNPAID_INVOICES: string = "UNPAID_INVOICES";
export const CARRIER_BROKER: string = "CARRIER_BROKER";
export const STATISTICS: string = "STATISTICS";
export const CAPTCHA: string = "CAPTCHA";
export const DRIVER_ID: string = "DRIVER_ID";
export const DEVICE_ID: string = "DEVICE_ID";
export const EXTENSION_COUNT: string = "EXTENSION_COUNT";
export const ASSOCIATION_COUNT: string = "ASSOCIATION_COUNT";
export const EXTEND = "EXTEND";
export const FUTURE_RECURRENCE: string = "FUTURE_RECURRENCE";
export const TOTAL_INVOICES: string = "TOTAL_INVOICES";
export const PAID_INVOICES: string = "PAID_INVOICES";
export const PENDING_INVOICES: string = "PENDING_INVOICES";
export const FAILED_INVOICES: string = "FAILED_INVOICES";
export const EXPIRED_INVOICES: string = "EXPIRED_INVOICES";
export const CANCELLED_INVOICES: string = "CANCELLED_INVOICES";
export const PARKING_LOT_COUNT: string = "PARKING_LOT_COUNT";
export const CARRIER_NETWORK: string = "CARRIER_NETWORK";
export const CARRIER_ORGANIZATION: string = "CARRIER_ORGANIZATION";
export const ADD_CARRIER: string = "ADD_CARRIER";
export const PAYMENTS: string = "PAYMENTS";
export const SUBSCRIPTION_INVOICE: string = "SUBSCRIPTION_INVOICE";
export const PROGRESS: string = "PROGRESS";
export const DISCOUNT: string = "DISCOUNT";
export const RESERVATION_COUNT: string = "RESERVATION_COUNT";
export const SUBSCRIPTION_CODE: string = "SUBSCRIPTION_CODE";
export const LICENCE_NO: string = "LICENCE_NO";
export const ASSOCIATED_COMPANY: string = "ASSOCIATED_COMPANY";
export const MAKE_MODAL: string = "MAKE_MODAL";
export const MAKE_YEAR: string = "MAKE_YEAR";
export const COLOR: string = "COLOR";
export const NUMBER_OF_AXLES: string = "NUMBER_OF_AXLES";
export const LENGTH_OF_TRAILER: string = "LENGTH_OF_TRAILER";
export const ADD_ASSET: string = "ADD_ASSET";
export const BULK_IMPORT: string = "BULK_IMPORT";
export const BOOKING_FAILED: string = "BOOKING_FAILED";
export const CANCELLED: string = "CANCELLED";
export const EXPIRED: string = "EXPIRED";
export const FAILED: string = "FAILED";
export const PAYMENT_PENDING: string = "PAYMENT_PENDING";
export const PAYMENT_INITIATED: string = "PAYMENT_INITIATED";
export const REMOVED: string = "REMOVED";
export const SELECT_STATUS: string = "SELECT_STATUS";
export const UPCOMING: string = "UPCOMING";
export const PAYMENT_REFUNDED: string = "PAYMENT_REFUNDED";
export const PARKING_CHARGES: string = "PARKING_CHARGES";
export const PLATFORM_FEE_TAX: string = "PARKING_CHARGES";
export const ADD_EQUIPMENT_TYPE: string = "ADD_EQUIPMENT_TYPE";
export const TAX_NUMBER: string = "TAX_NUMBER";
export const BOOK_RESERVATION: string = "BOOK_RESERVATION";
export const RESET: string = "RESET";
export const SHARE: string = "SHARE";
export const MOBILE_APP_DRIVER: string = "MOBILE_APP_DRIVER";
export const DRIVER_EMAIL_ID: string = "DRIVER_EMAIL_ID";
export const COUNTRY_CODE: string = "COUNTRY_CODE";
export const DOT_NUMBER: string = "DOT_NUMBER";
export const CREATE_RESERVATION: string = "CREATE_RESERVATION";
export const EXTEND_CANCEL_RESERVATION: string = "EXTEND_CANCEL_RESERVATION";
export const CREDIT_CARD: string = "CREDIT_CARD";
export const USD: string = "USD";
export const CAD: string = "CAD";
export const YES: string = "YES";
export const NO: string = "NO";
export const BROWSE_FILES: string = "BROWSE_FILES";
export const DRAG_DROP: string = "DRAG_DROP";
export const OR: string = "OR";
export const TEST_PARKING_LOT: string = "TEST_PARKING_LOT";
export const UPLOAD_CSV_IMPORT: string = "UPLOAD_CSV_IMPORT";
export const UPLOAD_CSV_FILE: string = "UPLOAD_CSV_FILE";
export const FILE_NAME: string = "FILE_NAME";
export const UPLOAD: string = "UPLOAD";
export const PHONE_NO: string = "PHONE_NO";
export const ADD_TRUCK: string = "ADD_TRUCK";
export const VIN_NUMBER: string = "VIN_NUMBER";
export const SUBSCRIPTION_ID: string = "SUBSCRIPTION_ID";
