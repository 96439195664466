import { useNavigate } from 'react-router-dom';
import {getAxiosWithOutToken} from '../api/axios';
import { LocalStorageUtil } from '../utils/LocalStorageUtil';
import { reDirectURLTo } from '../utils/redirectionUtil';
// import useAuth from './useAuth';

const useRefreshToken = () => {
    // const { setAuth } = useAuth();
    const navigate = useNavigate();

    const refresh = async () => {
        try{
            const axios = getAxiosWithOutToken();
            const refreshToken = JSON.parse(String(LocalStorageUtil.getItem("refreshToken")));
            const response = await axios.post("/api/auth/refreshToken", {
                "refreshToken": refreshToken
            });

            const token:any = response.data.token;
            LocalStorageUtil.setItem("token", JSON.stringify(token));
            return response.data.token;
        }
        catch(e){            
            reDirectURLTo(navigate,'/');
            return '';
        }
    }
    return refresh;
};

export default useRefreshToken;