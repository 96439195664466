import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import { dashPage } from "../../utils/constant/dashboardPages";
import { useSidebarContext } from "../../Contexts/SidebarContext";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import { styled } from "@mui/material/styles";
import { LocalStorageUtil } from "../../utils/LocalStorageUtil";

const StyledList = styled(List)({
  // selected and (selected + hover) states

  "& .MuiListItem-root:hover": {
    width: "100%",
    backgroundColor: "#F4F9FF",
    color: "#2C7EF4",
    borderRight: "3px solid #2C7EF4",

    "&, & .MuiListItemIcon-root": {
      color: "#2C7EF4",
    },
    "&, & .MuiListItemIcon-root:hover": {
      color: "#2C7EF4",
    },
  },
  "& .MuiTypography-root:hover": {
    color: "#2C7EF4",

    "&, & .MuiTypography-root": {
      color: "#2C7EF4",
    },
  },
  // hover states
  "& .MuiListItemButton-root:hover": {
    backgroundColor: "#F4F9FF",
    color: "#2C7EF4",
    borderRight: "3px solid #2C7EF4",
    "&, & .MuiListItemIcon-root": {
      color: "#2C7EF4",
    },
  },
  "&& .Mui-selected, && .Mui-selected:hover": {
    backgroundColor: "#F4F9FF",
    borderRight: "3px solid #2C7EF4",
    color: "#2C7EF4",
    "& .MuiTypography-root": {
      color: "#2C7EF4",
    },
  },
});
let isApproved: string = "";

const SideDrawer = () => {
  const { openSidebar, sideBar, user, orgType ,isOrgNetworkConfigEnabled} = useSidebarContext();
  const [menuData, setMenuData] = useState<any>([]);
  const [open, setOpen] = React.useState(true);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [selectedName, setSelectedName] =
    React.useState<string>("Map Dashboard");
  const location = useLocation();

  useEffect(() => {
    let userType = "";
    if (user == 1) {
      userType = "admin";
    } else {
      userType = "user";
    }

    const chekOrganizationType = () => {
      const viewMenu = dashPage(user);
    };

    const getMenuDataByType = (type: string, list: any) => {
      console.log(type, list, "TYPE");
      if (type === "ADMIN") return list;
      //else if (type === "PARKING_LOT_OWNER") return list;
      else if (
        type === "CARRIER" ||
        type === "PARKING_LOT_OWNER" ||
        type === "BROKER"
      )
        return list.filter((items: any) =>
          items?.orgType ? items?.orgType?.includes(type) : true
        );

      return list;
    };

    const dashboaradPages2: any = getMenuDataByType(orgType, dashPage(user));
    let userrole: any = "";

    try {
      userrole = JSON.parse(LocalStorageUtil.getItem("role") || "");
    } catch (e) {
      userrole = "";
    }

    //const newOrgTypeFileter = getMenuDataByType(orgType, dashboaradPages2);

    console.log(userrole, orgType, dashboaradPages2, "ORGddddd");

    const fil: any = dashboaradPages2
      .filter((page: any) => page.view === "both" || page.view === userType)
      .filter((page: any) => page.notFor !== userrole)
      .map((item: any) => {
        if (item?.childrenRoute?.length > 0) {
          return {
            ...item,
            childrenRoute: item.childrenRoute
              .filter((page: any) => page.notFor !== userrole)
              .filter(
                (cpage: any) => cpage.view === "both" || cpage.view === userType
              ),
          };
        }
        return item;
      });

    console.log(fil, "FIL");

    setMenuData(fil);
    try {
      let org: any = LocalStorageUtil.getItem("isApproved");
      isApproved = JSON.parse(org);
    } catch (e) {
      isApproved = "";
    }
  }, [user, orgType]);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <Drawer
        BackdropProps={{ invisible: true }}
        anchor={"left"}
        open={sideBar}
        onClose={() => openSidebar()}
        style={{ zIndex: 900, width: "100%" }}
      >
        <Box sx={{ width: 230, marginTop: 8 }}>
          <StyledList>
            {menuData?.length > 0 &&
              menuData.map((page: any, index: any) => {
                if(page.carrierNetwork != undefined && isOrgNetworkConfigEnabled != true && LocalStorageUtil.getItem("saasOrgId") != "1"){
                  return null;
                }
                return (
                  <React.Fragment key={page.id}>
                    {page.role === "user" ? (
                      <ListItem
                        component={Link}
                        to={page.pageRoute}
                        onClick={() => {
                          setSelectedIndex(index);
                          setSelectedName(page.pageName);
                          openSidebar();
                        }}
                        style={{ width: "100%" }}
                        className="sideHover"
                        selected={location.pathname === page.pageRoute}
                      >
                        <div
                          style={{
                            marginTop: "20px",
                            color: "#050505",
                            display: "flex",
                          }}
                        >
                          <i
                            className={page.iconClass}
                            style={{ marginRight: "10px" }}
                          ></i>
                          <Typography
                            style={{ fontSize: "13px", fontWeight: "450" }}
                          >
                            {page.pageName}
                          </Typography>
                        </div>
                      </ListItem>
                    ) : (
                      <SubList
                        page={page}
                        setSelectedIndex={setSelectedIndex}
                        setSelectedName={setSelectedName}
                        selectedName={selectedName}
                      />
                    )}
                  </React.Fragment>
                );
              })}
          </StyledList>
        </Box>
      </Drawer>
    </>
  );
};

const SubList = ({
  page,
  setSelectedIndex,
  selectedName,
  setSelectedName,
}: any) => {
  const { openSidebar, sideBar, user, orgType } = useSidebarContext();
  const [open, setOpen] = useState(false);
  const [selectedIndex2, setSelectedIndex2] = React.useState(0);
  const location = useLocation();
  const basePath = location.pathname.split("/")[1]
    ? `/${location.pathname.split("/")[1]}`
    : location.pathname;
  const handleClick = () => {
    setOpen(!open);
  };
  const handleSelectLink = (e: any, name: string) => {
    // setSelectedIndex(selectedIndex + 2);
    setSelectedName(name);
    openSidebar();
  };

  const getMenuDataByType = (type: string, list: any) => {
    if (type === "ADMIN") return list;
    //else if (type === "PARKING_LOT_OWNER") return list;
    else if (
      type === "CARRIER" ||
      type === "PARKING_LOT_OWNER" ||
      type === "BROKER"
    )
      return list.filter((items: any) =>
        items?.orgType ? items?.orgType?.includes(type) : true
      );

    return list;
  };
  const subMenu = getMenuDataByType(orgType, page?.childrenRoute);

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <div style={{ marginTop: "15px", color: "black", display: "flex" }}>
          <i className={page.iconClass} style={{ marginRight: "10px" }}></i>
          <Typography style={{ fontSize: "13px", fontWeight: "450" }}>
            {page.pageName}
          </Typography>

          {open ? <ExpandLess /> : <ExpandMore />}
        </div>
      </ListItemButton>
      <Collapse in={open} timeout="auto">
        {subMenu
          .filter((ele: any) => {
            // if (isApproved !== "Approved" && isApproved !== undefined) {
            //   if (ele.pageRoute !== "/parkingLot") return true;
            //   else return false;
            // } else
            return true;
          })
          ?.map((childPage: any, index: any) => {
            return (
              <ListItem
                component={Link}
                to={childPage.pageRoute}
                onClick={(e) => handleSelectLink(e, childPage.pageName)}
                selected={
                  basePath === childPage.pageRoute ||
                  basePath === childPage.innerRoute
                }
                key={childPage.id}
              >
                <div
                  style={{
                    marginTop: "4px",
                    paddingLeft: "50px",
                    color: "black",
                  }}
                >
                  <Typography style={{ fontSize: "13px", fontWeight: "450" }}>
                    {childPage.pageName}
                  </Typography>
                </div>
              </ListItem>
            );
          })}
      </Collapse>
    </>
  );
};

export default SideDrawer;
